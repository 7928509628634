import { Flavors, HipType } from "../../../constants";
import GlobalConfig from "../../../global-config";
import {
  type ConfirmSendCredential,
  type OneTimeCodeCredential,
  type UserCredential,
  CredentialType,
} from "../../../model/credential";
import { ProofType } from "../../../model/proof";
import { cleanseUsername } from "../../../model/user";
import { type SystemActionName } from "../../../telemetry-helpers/system-action-name";
import { type UntrustedExternalInputText } from "../../untrusted-external-input-text";
import {
  type OtcFormApiTelemetryProps,
  type OtcFormParams,
  getOneTimeCode as getOtcForm,
} from "./get-one-time-code-form";
import { type OtcJsonParams, getOneTimeCode as getOtcJson } from "./get-one-time-code-json";
import {
  type OtcChannel,
  type OtcFailureParams,
  type OtcSuccessParams,
} from "./one-time-code-types";

// TODO: Update how 'postOtcRequest()' determines the Otc Method (json vs form). Suggested change here: https://msazure.visualstudio.com.office.mcsoft.mcsoft.myshn.net/DefaultCollection/One/_git/AD-Identity-UX/pullRequest/8660979#1693595825
export type OtcHelperParams = OtcJsonParams & OtcFormParams;

/**
 * **Note: Do not use this method directly!** Use `getOneTimeCode` instead, which implements this.
 * This method handles making a "GetOneTimeCode" request one of two ways.
 * If the "getOtcJsonUrl" is defined, a JSON POST is made. (Currently only used by AAD)
 * Otherwise, a POST using a query string as the body is made.
 * @param params the parameters required to make the request
 * @returns a promise that will resolve to the OTC success parameters (or throw an error on failure)
 */
export const postOtcRequest = function postOtcRequest(params: OtcHelperParams) {
  const useOtcJson = !!params.getOtcJsonUrl;
  const getOtcMethod = useOtcJson ? getOtcJson : getOtcForm;
  return getOtcMethod(params);
};

export type GetOtcParams = {
  onSuccess: (successValues: OtcSuccessParams) => void;
  onFailure: (error: OtcFailureParams) => void;
  canaryFlowToken?: string;
  channel: OtcChannel | null;
  flowToken: string;
  isEncrypted?: boolean;
  proofConfirmation?: string;
  proofData?: string;
  proofType: ProofType;
  purpose: string;
  sessionIdentifier?: string;
  username: UntrustedExternalInputText;
  challengeSolution?: string;
  challengeType?: HipType;
  challengeId?: string;
  challengeViewSupported?: string;
  phoneRepMetadata?: string;
  telemetryCallback?: (
    actionName: SystemActionName,
    telemetryProps: OtcFormApiTelemetryProps,
  ) => void;
};

/**
 * This method is a helper method that calls the getOneTimeCodeHelper by taking input parameters and configuration.
 * @param params The OTC parameters required for the request (that aren't loaded from Config)
 * @returns A promise that will call the onSuccess/onFailure handlers before being resolved.
 */
export const getOneTimeCode = async function getOneTimeCode(params: GetOtcParams) {
  // Note: Not all possible variables are implemented yet - only those required
  const {
    onSuccess,
    onFailure,
    canaryFlowToken,
    channel,
    flowToken,
    isEncrypted,
    proofConfirmation,
    proofData,
    proofType,
    purpose,
    sessionIdentifier,
    username,
    challengeSolution,
    challengeType,
    challengeId,
    challengeViewSupported,
    phoneRepMetadata,
    telemetryCallback,
  } = params;
  const {
    context,
    unauthenticatedSessionId,
    siteId,
    clientId,
    getOtcJsonUrl,
    localeId,
    forwardedClientId,
    noPaBubbleVersion,
  } = GlobalConfig.instance;
  const cleansedUsername = cleanseUsername(username.unsafeUnescapedString);

  const otcParams: OtcHelperParams = {
    canaryFlowToken,
    channel,
    clientId,
    flowToken,
    forwardedClientId,
    getOtcJsonUrl,
    isEncrypted,
    localeId,
    noPaBubbleVersion,
    originalRequest: context,
    proofConfirmation,
    proofData,
    proofType,
    purpose,
    sessionIdentifier,
    siteId,
    unauthSessionId: unauthenticatedSessionId,
    username: cleansedUsername,
    challengeSolution,
    challengeType,
    challengeId,
    challengeViewSupported,
    phoneRepMetadata,
    telemetryCallback,
  };

  return postOtcRequest(otcParams).then(onSuccess, onFailure);
};

/**
 * @param currentCredential The current OneTimeCode credential being used
 * @param proofType The ProofType for the user's current credential
 * @returns The current credentials proof display for Email proof types.
 * Returns the current credentials proof display, cleansed, for SMS and Voice proof types.
 * Returns undefined otherwise.
 */
export const getProofConfirmation = (
  currentCredential: OneTimeCodeCredential,
  proofType: ProofType,
) => {
  const currentProofDisplay = currentCredential.proof.display;

  switch (proofType) {
    case ProofType.Email:
      return currentProofDisplay;
    case ProofType.SMS:
    case ProofType.Voice:
      // Returns the last four characters, cleansed
      return cleanseUsername(currentProofDisplay).slice(-4);
    default:
      return undefined;
  }
};

/**
 * @param currentCredential The current credential being used
 * @returns Whether or not the current credential is an encrypted OneTimeCode credential
 */
export const isProofEncrypted = (
  currentCredential: ConfirmSendCredential,
): currentCredential is OneTimeCodeCredential =>
  currentCredential.credentialType === CredentialType.OneTimeCode &&
  !!currentCredential.proof.isEncrypted;

/**
 * @param currentCredential The current credential being used
 * @param availableCredentials The user credentials available for authentication
 * @returns Whether or not the user is passwordless, for the purpose of using the no password type
 */

export const isNoPassword = (
  currentCredential: ConfirmSendCredential,
  availableCredentials: UserCredential[],
) => {
  if (currentCredential.credentialType === CredentialType.OneTimeCode) {
    // Return if the OTC credential is NoPassword
    return currentCredential.proof.isNopa;
  }

  // Check if there is a password credential, if there is none, the user is passwordless
  return !availableCredentials.find(
    (credential) => credential.credentialType === CredentialType.Password,
  );
};

export const convertStringToHipType = (hipTypeString?: string): HipType => {
  switch (hipTypeString) {
    case "audio":
      return HipType.Audio;
    case "visual":
      return HipType.Visual;
    case "sms":
      return HipType.Sms;
    case "enforcement":
      return HipType.Enforcement;
    case "block":
      return HipType.Block;
    default:
      return HipType.Unknown;
  }
};

/**
 * return flag that indicates whether the active flavor supports challenge view
 * that is required to show captcha during otc login
 * @param activeFlavor current app flavor
 * @returns if challenge view is supported for the flavor
 */
export const isChallengeViewSupported = (activeFlavor: Flavors): string => {
  switch (activeFlavor) {
    case Flavors.Fabric:
    case Flavors.Win11OobeFabric:
      return "true";

    default:
      return "false";
  }
};
